<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="病例列表" />
        <el-main>
            <div class="notfound">
                <h1>即将上线</h1>
                <h2>请稍后访问</h2>
                <el-button size="large" @click="goback" type="primary">
                    返 回
                </el-button>
            </div>
        </el-main>
    </div>
</template>

<script>
export default {
    name: 'hdata_patient',
    path: '/main/hdata/patient',
    methods: {
        goback() {
            this.$router.go(-1)
        }
    }
}
</script>


<style scoped>
h1 {
    font-size: 400%;
}

.notfound {
    padding: 40px 0;
    text-align: center;
}
</style>
