<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from 'echarts/components';
import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent
]);

export default {
    name: "genderBarChart",
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "light"
    },
    data() {
        return {
            option: {
                title: {
                    text: '男女分布',
                    left: "center"
                },
                color:['#de6e6a','#5971c0','#FCCE10','#E87C25','#B5C334'],
                xAxis: {
                    type: 'category',
                    data: ['大庆油田总医院', '大庆市人民医院', '大庆市龙南医院', '大庆市第二医院']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar'
                    },
                    {
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar'
                    }
                ]
            }
        };
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
