<template>
    <el-dialog title="编辑" :visible="visible" width="40%" @close="close">
        <el-tabs v-model="activeName">
            <el-tab-pane label="员工信息" name="tab1">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="form.name" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="体检项目" prop="packages">
                        <el-checkbox-group v-model="form.packages">
                            <el-checkbox label="血常规"></el-checkbox>
                            <el-checkbox label="尿常规"></el-checkbox>
                            <el-checkbox label="生化"></el-checkbox>
                            <el-checkbox label="肝、胆、胰、脾彩超"></el-checkbox>
                            <el-checkbox label="胸片"></el-checkbox>
                            <el-checkbox label="胸部CT"></el-checkbox>
                            <el-checkbox label="甲状腺彩超"></el-checkbox>
                            <el-checkbox label="肌电图"></el-checkbox>
                            <el-checkbox label="头部核磁"></el-checkbox>
                            <el-checkbox label="凝血功能"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model.trim="form.desc"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{$t('form.cancel')}}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{$t('form.ok')}}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            packages:[]
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'tab1',
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
            if (!valid) return
            const resp = await this.$http.post('/packages', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit("close")
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
/deep/ .el-dialog__body {
    padding: 0 20px;
}
</style>
