<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
    import { use } from "echarts/core";
    import { CanvasRenderer } from "echarts/renderers";
    import { PieChart } from "echarts/charts";
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from "echarts/components";
    import VChart, { THEME_KEY } from "vue-echarts";

    use([
        CanvasRenderer,
        PieChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent
    ]);

    export default {
        name: "agePieChart",
        components: {
            VChart
        },
        provide: {
            [THEME_KEY]: "white"
        },
        data() {
            return {
                option: {
                    title: {
                        text: "年龄分布",
                        left: "center"
                    },
                    color:['#de6e6a','#5971c0','#FCCE10','#E87C25','#B5C334'],
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    series: [
                        {
                            name: "年龄分布",
                            type: "pie",
                            radius: "55%",
                            center: ["50%", "60%"],
                            data: [
                                // { value: Math.floor(Math.random() * 1000), name: "<20岁" },
                                // { value: Math.floor(Math.random() * 1000), name: "20-30岁" },
                                // { value: Math.floor(Math.random() * 1000), name: "30-40岁" },
                                // { value: Math.floor(Math.random() * 1000), name: "40-50岁" },
                                // { value: Math.floor(Math.random() * 1000), name: "50-60岁" },
                                // { value: Math.floor(Math.random() * 1000), name: ">60岁" },
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)"
                                }
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            async getData() {
                const resp = await this.$http.get('/admin/statistics/chart/agePie/')
                console.log(222, resp.data)
                // this.option.series[0].data = resp.data
                // this.option = resp.data.data
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 400px;
    }
</style>
