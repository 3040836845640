<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from 'echarts/components';
import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent
]);

export default {
    name: "ageBarChart",
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "light"
    },
    data() {
        return {
            option: {
                title: {
                    text: '年龄分布',
                    left: "center"
                },
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {
                //         type: 'shadow'
                //     },
                //     formatter: function (params) {
                //         var tar = params[1];
                //         return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
                //     }
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Forest', 'Steppe']
                },
                color: ['#de6e6a', '#5971c0', '#FCCE10', '#E87C25', '#B5C334'],
                xAxis: {
                    type: 'category',
                    data: ['<20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '>80']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '男',
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar'
                    },
                    {
                        name: '女',
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar'
                    }
                ]
            }
        };
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
