import { render, staticRenderFns } from "./radioDepartment.vue?vue&type=template&id=1d4f84ba&scoped=true&"
import script from "./radioDepartment.vue?vue&type=script&lang=js&"
export * from "./radioDepartment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../_webui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d4f84ba",
  null
  
)

export default component.exports