<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="基础数据统计" />
        <div class="count-cards" v-loading="loading">
                <count-card title="病例总数" :count="counts.zongshu" icon="el-icon-user"></count-card>
                <count-card title="首诊记录数" :count="counts.shouzhen" icon="el-icon-s-order" color="rgb(95, 191, 126)"></count-card>
                <count-card title="复诊记录数" :count="counts.fuzhen" icon="el-icon-news" color="rgb(195, 191, 226)"></count-card>
                <count-card title="患者填报数" :count="counts.tianbao" icon="el-icon-attract" color="rgb(141, 210, 198)"></count-card>
                <count-card title="医生录入" :count="counts.luru" icon="el-icon-bangzhu" color="rgb(121, 170, 198)"></count-card>
                <count-card title="随访记录数" :count="counts.suifang" color="rgb(144, 147, 153)" icon="el-icon-s-promotion"></count-card>
        </div>
        <el-row style="margin:30px 0" v-loading="loading">
            <el-col :span="16">
                <pie-chart :option="ageBarChart" />
            </el-col>
            <el-col :span="8">
                <pie-chart :option="agePieChart" />
            </el-col>
            <el-col :span="16">
                <pie-chart :option="genderAgeBarChart" />
            </el-col>
            <el-col :span="8">
                <pie-chart :option="genderPieChart" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'statistics-base',
    path: '/main/statistics/base',
    data() {
        return {
            loading: false,
            counts: {
                zongshu: '-',
                shouzhen: '-',
                fuzhen: '-',
                suifang: '-',
                tianbao: '-'
            },
            agePieChart: {}
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/admin/statistics/base/counts/')
            this.counts = resp.data
            const agePieChart = await this.$http.get('/admin/statistics/chart/agePieChart')
            this.agePieChart = agePieChart.data
            const ageBarChart = await this.$http.get('/admin/statistics/chart/ageBarChart')
            this.ageBarChart = ageBarChart.data
            const genderAgeBarChart = await this.$http.get('/admin/statistics/chart/genderAgeBarChart')
            this.genderAgeBarChart = genderAgeBarChart.data
            const genderPieChart = await this.$http.get('/admin/statistics/chart/genderPieChart')
            this.genderPieChart = genderPieChart.data
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.count-cards{
    margin-top: 10px;
    display: flex;
    div{
        margin: 10px;
        flex:1;
    }
}
</style>