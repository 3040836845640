import { render, staticRenderFns } from "./assets.vue?vue&type=template&id=624afcea&scoped=true&"
import script from "./assets.vue?vue&type=script&lang=js&"
export * from "./assets.vue?vue&type=script&lang=js&"
import style0 from "./assets.vue?vue&type=style&index=0&id=624afcea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../_webui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624afcea",
  null
  
)

export default component.exports