<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="分项数值筛选" />
        <el-form :inline="true" :model="form" size="mini"
            style="text-align: left;margin-top:15px;background-color: #f7f7f8;padding-top: 20px;padding-left:20px;border:1px solid #EBEEF5">
            <el-form-item>
                <el-select v-model="form.hospital" placeholder="请选择机构" style="width:155px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="大庆油田总医院" value="1st"></el-option>
                    <el-option label="大庆市人民医院" value="renmin"></el-option>
                    <el-option label="大庆市龙南医院" value="longnan"></el-option>
                    <el-option label="大庆市第二医院" value="2st"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="form.daterange" type="datetimerange" style="width:500px;"
                    value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始时间"
                    end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini">查询</el-button>
                <el-button type="default" size="mini">导出Excel</el-button>
            </el-form-item>
            <el-row>
                <el-col :span="24" v-for="(f,i) in form.search" :key="i">
                    <el-form-item>
                        <el-button icon="el-icon-plus" circle @click="addSearch"></el-button>
                        <el-button icon="el-icon-minus" circle @click="removeSearch"></el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form.search[i].and" placeholder="关系" style="width:80px">
                            <el-option label="并且" value="and"></el-option>
                            <el-option label="或" value="or"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-cascader v-model="form.search[i].choose" :options="packages" @change="packageChange" style="width:240px" />
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form.search[i].comp" placeholder="比较" style="width:80px">
                            <el-option label="等于" value="eq"></el-option>
                            <el-option label="大于" value="gt"></el-option>
                            <el-option label="小于" value="lt"></el-option>
                            <el-option label="大于等于" value="gte"></el-option>
                            <el-option label="小于等于" value="lte"></el-option>
                            <el-option label="包含" value="include"></el-option>
                            <el-option label="除外" value="exclude"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.search[i].keywords"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="date" label="时间" width="180" />
            <el-table-column prop="name" label="姓名" width="180" />
            <el-table-column prop="package" label="项目" />
            <el-table-column prop="package" label="值" />
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'stat-screen',
    path: '/main/statistics/screen',
    data() {
        return {
            form: {
                search: [{}]
            },
            tableData: [],
            packages: [
                {
                    value: 'userinfo',
                    label: '用户信息',
                    children: [{
                        value: 'base',
                        label: '基本信息',
                        children: [{
                            value: 'age',
                            label: '年龄'
                        },{
                            value: 'gender',
                            label: '性别'
                        }]
                    }]
                },
                {
                    value: 'userinfo',
                    label: '检验结果',
                    children: [{
                        value: 'base',
                        label: '血常规',
                        children: [{
                            value: 'wbc',
                            label: 'WBC'
                        },{
                            value: 'rbc',
                            label: 'RBC'
                        },{
                            value: 'hb',
                            label: 'Hb'
                        },{
                            value: 'plt',
                            label: 'PLT'
                        }]
                    },{
                        value: 'base',
                        label: '肝功能',
                        children: [{
                            value: '总蛋白',
                            label: '总蛋白'
                        },{
                            value: '谷丙转氨酶',
                            label: '谷丙转氨酶'
                        },{
                            value: '谷草转氨酶',
                            label: '谷草转氨酶'
                        },{
                            value: '白蛋白',
                            label: '白蛋白'
                        }]
                    }]
                }, {
                    value: 'userinfo',
                    label: '检查结果',
                    children: [{
                        value: 'base',
                        label: '胸片',
                        children: [{
                            value: '检查所见',
                            label: '检查所见'
                        },{
                            value: '检查结论',
                            label: '检查结论'
                        }]
                    },{
                        value: 'base',
                        label: '头部核磁共震',
                        children: [{
                            value: '检查所见',
                            label: '检查所见'
                        },{
                            value: '检查结论',
                            label: '检查结论'
                        }]
                    }]
                }
            ]
        }
    },
    methods: {
        addSearch() {
            this.form.search.push({})
        },
        removeSearch() {
            this.form.search.pop()
        },
        packageChange(value) {
            console.log(value)
        }
    }
}
</script>
