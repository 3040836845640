<template>
    <v-chart class="chart" :option="option||option2" />
</template>

<script>
    import { use } from "echarts/core";
    import { CanvasRenderer } from "echarts/renderers";
    import { PieChart } from "echarts/charts";
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from "echarts/components";
    import VChart, { THEME_KEY } from "vue-echarts";

    use([
        CanvasRenderer,
        PieChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent
    ]);

    export default {
        name: "pieChart",
        components: {
            VChart
        },
        provide: {
            [THEME_KEY]: "white"
        },
        props: ['option'],
        data() {
            return {
                option2: {
                }
            };
        }
    };
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 400px;
    }
</style>
