<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="病例附件" />
        <div class="todo">
            patient assets
            <div>请稍后访问</div>
        </div>
    </div>
</template>

<script>
export default {
    name: '附件列表',
    path: '/main/hdata/assets'
}
</script>

<style lang="scss" scoped>
.todo {
    padding: 30px;
}
</style>