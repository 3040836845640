export default {
    app_title: '高血压管理程序',
    app: {
        title: '高血压管理程序',
        home: '首页',
        passwd: '修改密码',
        logout: '退出',
    },
    menu: {
        dashboard: '系统首页',
        hdata: '病例管理',
        hdata_patient: '病例列表',
        hdata_assets: '病例附件',
        dispatch: '数据分配',
        dispatch_community: '分配社区',
        dispatch_doctor: '分配医生',
        
        statistics: '统计分析',
        statistics_base: '基础数据统计',
        statistics_workload: '工作量统计',
        statistics_screen: '分项数值筛选',
        statistics_quality: '数据质量',
        statistics_bp: '血压分析',
        
        settings: '系统设置',
        settings_user: '用户管理',
        oplog: '系统日志',
    },
    form: {
        search: '搜索',
        ok: '确认',
        cancel: '取消',
        export_xsl: '导出Excel'
    }
}
