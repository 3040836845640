<template>
    <div>
        <el-row>
            <el-col :span="12">
                <div style="padding: 20px">
                    <h1 style="font-size: 200%">高血压慢病管理系统</h1>
                    <p>高血压慢病管理系统，高血压慢病管理系统、高血压慢病管理系统、</p>
                    <p>高血压慢病管理系统、高血压慢病管理系统、高血压慢病管理系统。</p>
                    <p>
                        <a href="/docs/index.html" target="_blank">API 接口文档</a>
                    </p>
                </div>
            </el-col>
            <el-col :span="12" style="text-align: right">
               <img src="./assets/hero.png" width="500px"/>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'dashboard',
        path: '/main/dashboard',
        data() {
            return {
                count: {}
            }
        }
    }
</script>

<style scoped>
</style>
