import Mock from '../utils'

const Random = Mock.Random

let gender = ['男', '女']

let doctors = ['张医生', '黄医生', '李医生']

let hospital = ['大庆油田总医院', '大庆市人民医院', '大庆市龙南医院', '大庆市第二医院']

let packages = ['综合套餐', '心脑血管套餐', '消化肿瘤套餐', '妇科类套餐']

let fee = ['688.00', '888.00', '998.00', '1000.00', '1200.00', '1800.00', '2000.00', '2400.00', '3000.00', '5000.00']

function makeData() {
    let result = []
    for (let i = 0; i < 500; i++) {
        let date = new Date();
        let offset = Random.integer(0, 1000)
        date.setDate(date.getDate() + 0 - offset) // 病例时间
        // let day = date.format('yyyy-MM-dd')

        let obj = {
            id: 10000+i,
            created: Random.fdate(-365, 0, [1,2,3])+ ' ' + Random.ftime(),
            typeId: Random.integer(1, 6),
            name: Random.cname(),
            id_num: Random.id(),
            age: Random.integer(14, 80),
            gender: Random.pick(gender),
            doctor: doctors[Random.integer(0, 2)],
            hospital: Random.pick(hospital),
            package: Random.pick(packages),
            fee: Random.pick(fee),
        }
        result.push(obj)
    }
    return result
}

let data = makeData()


Mock.api('/api/staff/:id', 'get', (req) => {
    let qs = data.filter(e => e.id == req.params.id)
    return {code: 200, msg: '成功', data: qs[0]}
})

Mock.api('/api/staff', 'get', (req) => {
    let qs = data.filter(e => !req.query.name || e.name.toLowerCase().includes(req.query.name.toLowerCase()))
    qs = qs.filter(e => !req.query.id_num || e.id_num.toLowerCase().includes(req.query.id_num))
    return {
        code: 200,
        data: {
            total: qs.length,
            records: Mock.pagination(qs, req.query.page, req.query.pageSize)
        }
    }
})

Mock.api('/api/staff', 'post', (req) => {
    const j = JSON.parse(req.body)
    if (j.id) {
        data.find(e => e.id == j.id).age = j.age
        data.find(e => e.id == j.id).gender = j.gender
        data.find(e => e.id == j.id).desc = j.desc
    } else {
        j.id = new Date().getTime()
        data.unshift(j)
    }
    return {code: 200, msg: '成功'}
})



Mock.api('/api/staff/:id', 'delete', (req) => {
    data.splice(data.findIndex(e => e.id == req.params.id), 1);
    return {code: 200, msg: '成功'}
})
