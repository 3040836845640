<template>
    <zk-admin-table title="系统日志" :loading="loading" :table="table" @change="handleTableChange"
                    @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)"
                           icon="el-icon-edit"></el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" :isNew="isNew"></data-form>
        </template>
        <template v-slot:download>
            <data-down-load :visible="downloadVisible" :form="search" :total="table.total" @close="downloadVisible=false"></data-down-load>
        </template>
    </zk-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'
    import DataDownLoad from './widgets/dataDownLoad'
    import data from './data'

    export default {
        name: 'main-oplog',
        path: '/main/oplog',
        components: {
            SearchForm,
            DataForm,
            DataDownLoad
        },
        data() {
            return {
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                downloadVisible: false,
                search: {},
                form: {},
                isNew: false,
                multipleSelection: []
            }
        },
        methods: {
            dialogClose() {
                this.dialogVisible = false
                this.getData()
            },
            doDownLoad() {
                this.downloadVisible = true
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleTableSelect(val) {
                this.multipleSelection = val;
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/oplog?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
