import Mock from 'mockjs'
import utils from '../utils'

let packages = [
    {id: 1, name: '综合套餐', packages:[], created:'2022-09-29 18:00:01', latest:'', desc: ''},
    {id: 2, name: '心脑血管套餐', packages:[], created:'2022-09-29 18:00:01', latest:'', desc: ''},
    {id: 3, name: '消化肿瘤套餐', packages:[], created:'2022-09-29 18:00:01', latest:'', desc: ''},
    {id: 4, name: '妇科类套餐', packages:[], created:'2022-09-29 18:00:01', latest:'', desc: ''},
]

Mock.mock(/\/api\/packages/, 'get', (req) => {
    let k = utils.getUrlQuery(req.url)
    let qs = packages
    qs = qs.filter(e => !k.keywords || e.name.toLowerCase().includes(k.keywords.toLowerCase()))
    return {
        code: 200,
        data: {
            total: qs.length,
            records: utils.pagination(qs, k.page, k.pageSize)
        }
    }
})

Mock.mock('/api/packages', 'post', (req) => {
    const j = JSON.parse(req.body)
    if (j.id) {
        packages.find(e => e.id == j.id).packages = j.packages
        packages.find(e => e.id == j.id).desc = j.desc
    } else {
        j.id = new Date().getTime()
        j.created = new Date().format('yyyy-MM-dd hh:mm:ss')
        packages.unshift(j)
    }
    return {code: 200, msg: '成功'}
})

Mock.mock(/\/api\/packages\/(\d+)/, 'delete', (req) => {
    let id = req.url.replace(/\/api\/packages\/(\d+)/, '$1')
    packages.splice(packages.findIndex(e => e.id == id), 1);
    return {code: 200, msg: '成功'}
})