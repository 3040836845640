const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: '140'},
            {title: '名称', field: 'name', width: '180'},
            {title: '创建时间', field: 'created', width: '180'},
            {title: '备注', field: 'desc', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
