<template>
    <zk-admin-table title="分配医生" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <el-form :inline="true" style="padding: 10px">
                <el-form-item label="选择医生" style="margin-bottom: 0">
                    <el-select size="small" v-model="form.doctor" placeholder="请选择">
                        <el-option :label="item.name" :value="item.id" v-for="item in doctorList" :key="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item style="margin-bottom: 0">
                    <el-button size="small" style="margin-left: 10px" type="primary" @click="handleSet">分配</el-button>
                </el-form-item>
            </el-form>
        </template>
        <el-table-column type="selection" align="center" width="50" />
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column>
    </zk-admin-table>
</template>

<script>
export default {
    name: 'dispatch_doctor',
    path: '/main/dispatch/doctor',
    data() {
        return {
            loading: false,
            searchVisible: true,
            table: {
                buttons: [],
                search: [],
                columns: [
                    { title: '名称', field: 'name', width: '200' },
                    { title: '社区', field: 'community', width: '200', formatter: null },
                    {
                        title: '医生',
                        field: 'doctor',
                        width: '120',
                        filters: [{ text: '焦莹', value: '焦莹' }, { text: '王玉君', value: '王玉君' }],
                    },
                    { title: '创建时间', field: 'created', width: '' },
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            },
            form: {
                community: '',
                multipleSelection: [],
            },
            params: {
                page: 1,
                pageSize: 20,
                doctor: []
            },
            doctorList: [],
        }
    },
    methods: {
        handleTableChange(e) {
            this.table = e
            this.params.page = e.page
            this.params.pageSize = e.pageSize
            this.getData()
        },
        handleTableSelect(val) {
            this.form.multipleSelection = val;
        },
        async handleSet() {
            const resp = await this.$http.post('/admin/set/doctor/', this.form)
            console.log(resp)
            this.getData()
        },
        getData() {
            this.loading = true
            setTimeout(function () {
                this.$http.get(`/admin/patient/`, { params: this.params }).then(resp => {
                    this.table.data = resp.data.data.data || []
                    this.table.total = resp.data.data.total || 0
                    this.loading = false
                })
            }.bind(this), 200)
        },
        getDoctorList() {
            this.$http.get(`/admin/doctor/`).then(resp => {
                console.log(resp.data)
                this.doctorList = resp.data.data
                let drc = []
                this.doctorList.forEach(e=>{
                    drc.push({text:e.name, value:e.name})
                })
                this.table.columns[2].filters = drc
            })
        },
    },
    mounted() {
        this.getDoctorList()
        this.getData()
    }
}
</script>