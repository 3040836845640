import Mock from './utils'
import './data/user'
import './data/menu'
import './data/packages'
import './data/hospital'
import './data/oplog'
import './data/staff'
import './data/whiteList'

Mock.setup({
    timeout: '100-300'
})

Mock.api('/api/version', () => {
    return {
        "url": "https://mingde.mriabc.com/download/",
        "version": "1.2.4"
        }
})
