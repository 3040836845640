<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="数据质量" />
        <el-form :inline="true" :model="form" size="mini"
            style="text-align: center;margin-top:30px;background-color: #f7f7f8;padding-top: 20px;border:1px solid #EBEEF5">
            <el-form-item label="时间">
                <el-date-picker v-model="form.daterange" size="small" type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始时间"
                    end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini">查询</el-button>
            </el-form-item>

        </el-form>
        <el-row style="margin:30px 0;min-height: 800px; border:0px solid red">
            <el-col :span="16" style="padding-right: 20px">
            </el-col>
            <el-col :span="8">
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'stat-quality',
    path: '/main/statistics/quality',
    data() {
        return {
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }],
            form: {}
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>

</style>