<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="血压分析" />
        <el-form :inline="true" :model="form" size="mini"
            style="text-align: center;margin-top:30px;background-color: #f7f7f8;padding-top: 20px;border:1px solid #EBEEF5">
            <el-form-item label="时间">
                <el-date-picker v-model="form.daterange" size="small" type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始时间"
                    end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" >查询</el-button>
            </el-form-item>

        </el-form>
        <el-row style="margin:30px 0">
            <el-col :span="16">
            </el-col>
            <el-col :span="8">
            </el-col>
            <el-col :span="24" style="background-color:#f7f7f8;border: 1px solid #eceef4;padding: 20px 0">
                <gender-dist-chart />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'stat-bp',
    path: '/main/statistics/bp',
    data() {
        return {
            form: {}
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
</style>