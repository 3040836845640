import Mock from '../utils'

let data = [
    {id: 1, name: '大庆市油田总医院', ipaddr:'222.34.101.200', created:'2022-09-29 18:00:01', latest:'', desc: ''},
    {id: 2, name: '大庆市人民医院', ipaddr:'68.134.10.2', created:'2022-09-29 18:00:01', latest:'', desc: ''},
    {id: 3, name: '大庆市龙南医院', ipaddr:'202.100.2.111', created:'2022-09-29 18:00:01', latest:'', desc: ''},
]

Mock.mock(/\/api\/whiteList/, 'get', (req) => {
    let k = Mock.getUrlQuery(req.url)
    let qs = data
    qs = qs.filter(e => !k.keywords || e.name.toLowerCase().includes(k.keywords.toLowerCase()))
    return {
        code: 200,
        data: {
            total: qs.length,
            records: Mock.pagination(qs, k.page, k.pageSize)
        }
    }
})

Mock.mock('/api/whiteList', 'post', (req) => {
    const j = JSON.parse(req.body)
    if (j.id) {
        data.find(e => e.id == j.id).ipaddr = j.ipaddr
        data.find(e => e.id == j.id).desc = j.desc
    } else {
        j.id = new Date().getTime()
        j.created = new Date().format('yyyy-MM-dd hh:mm:ss')
        data.unshift(j)
    }
    return {code: 200, msg: '成功'}
})

Mock.mock(/\/api\/whiteList\/(\d+)/, 'delete', (req) => {
    let id = req.url.replace(/\/api\/whiteList\/(\d+)/, '$1')
    data.splice(data.findIndex(e => e.id == id), 1);
    return {code: 200, msg: '成功'}
})
