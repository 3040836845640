<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { GridComponent } from 'echarts/components';
import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { VisualMapComponent } from 'echarts/components';
import { CalendarComponent } from 'echarts/components';
import { HeatmapChart } from 'echarts/charts';
use([VisualMapComponent]);
use([CalendarComponent]);
use([HeatmapChart]);

use([
    CanvasRenderer,
    LineChart,
    GridComponent,
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent
]);

export default {
    name: "calendarChart",
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "light"
    },
    data() {
        return {
            option: {
                title: {
                    top: 0,
                    left: 'center',
                    text: '全年用户分布热力图'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `<div>${params.value[0]}</div>
                                <div>人数: ${params.value[1]}</div>`;
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                visualMap: {
                    min: 0,
                    max: 1000,
                },
                calendar: [
                    {
                        range: '2022',
                        cellSize: ['auto', 20]
                    },
                    {
                        top: 260,
                        range: '2021',
                        cellSize: ['auto', 20]
                    },
                ],
                series: [
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        calendarIndex: 0,
                        data: this.getVirtulData('2022')
                    },
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        calendarIndex: 1,
                        data: this.getVirtulData('2021')
                    },
                ]
            }
        };
    },
    methods: {
        getVirtulData() {
            let data = []
            var now = new Date();
            for (let i = 0; i < 765; i++) {
                var day = new Date(now.getTime() - (24 * 60 * 60 * 1000) * i)
                data.push([
                    day.format('yyyy-MM-dd'),
                    Math.floor(Math.random() * 1000)
                ]);
            }
            return data;
        }
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
