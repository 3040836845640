import Mock from '../utils'

let users = [
    {id: 1, user_type: 1, nickname: '系统管理员', username: 'admin', password: 'admin@123', created:'2022-09-29 18:00:01', latest:'', desc: ''},
    {id: 2, user_type: 2, nickname: '集团用户001', username: 'user001', password: 'user@123', created:'2022-09-29 18:00:05', latest: '', desc: ''},
    {id: 3, user_type: 2, nickname: '集团用户002', username: 'user002', password: 'user@123', created:'2022-09-29 18:00:05', latest: '', desc: ''},
    {id: 4, user_type: 2, nickname: '集团用户003', username: 'user003', password: 'user@123', created:'2022-09-29 18:00:05', latest: '', desc: ''},
]

Mock.mock('/api/login', 'post', (req) => {
    const j = JSON.parse(req.body)
    let qs = users.filter(e => e.username === j.username && e.password === j.password)
    if (qs.length > 0) {
        qs[0].latest = new Date().format('yyyy-MM-dd hh:mm:ss')
        return {code: 200, msg: '', data: {token: j.username}}
    }
    else
        return {code: 201, msg: '用户名密码错误', data: {token: j.username}}
})

Mock.mock(/\/api\/user/, 'get', (req) => {
    let k = utils.getUrlQuery(req.url)
    let qs = users
    qs = qs.filter(e => !k.name || e.name.toLowerCase().includes(k.name.toLowerCase()))
    qs = qs.filter(e => !k.id_num || e.id_num.toLowerCase().includes(k.id_num))
    return {
        code: 200,
        data: {
            total: qs.length,
            records: utils.pagination(qs, k.page, k.pageSize)
        }
    }
})

Mock.mock('/api/user', 'post', (req) => {
    const j = JSON.parse(req.body)
    if (j.id) {
        users.find(e => e.id == j.id).name = j.name
        users.find(e => e.id == j.id).desc = j.desc
    } else {
        j.id = new Date().getTime()
        j.created = new Date().format('yyyy-MM-dd hh:mm:ss')
        users.unshift(j)
    }
    return {code: 200, msg: '成功'}
})

Mock.mock(/\/api\/user\/(\d+)/, 'delete', (req) => {
    let id = req.url.replace(/\/api\/user\/(\d+)/, '$1')
    users.splice(users.findIndex(e => e.id == id), 1);
    return {code: 200, msg: '成功'}
})