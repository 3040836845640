import { render, staticRenderFns } from "./genderPieChart.vue?vue&type=template&id=bad8ec08&scoped=true&"
import script from "./genderPieChart.vue?vue&type=script&lang=js&"
export * from "./genderPieChart.vue?vue&type=script&lang=js&"
import style0 from "./genderPieChart.vue?vue&type=style&index=0&id=bad8ec08&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../_webui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bad8ec08",
  null
  
)

export default component.exports