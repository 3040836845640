import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import http from "./http"

import './prototype'
import '../components'

Vue.use(ElementUI);
// Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.config.productionTip = false
Vue.prototype.$http = http
