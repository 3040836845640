import Mock from 'mockjs'

const dastboard = {
    id: '10',
    label: 'menu.dashboard',
    path: '/main/dashboard',
    icon: 'el-icon-view',
}
const hdata = {
    id: '20',
    label: 'menu.hdata',
    path: '/main/hdata',
    icon: 'el-icon-date',
    children: [
        {
            id: '20-1',
            label: 'menu.hdata_patient',
            path: '/main/hdata/patient',
        },
        {
            id: '20-2',
            label: 'menu.hdata_assets',
            path: '/main/hdata/assets',
        },
    ]
}
const dispatch = {
    id: '30',
    label: 'menu.dispatch',
    path: '/main/dispatch',
    icon: 'el-icon-user',
    children: [
        {
            id: '30-1',
            label: 'menu.dispatch_community',
            path: '/main/dispatch/community',
        },
        {
            id: '20-2',
            label: 'menu.dispatch_doctor',
            path: '/main/dispatch/doctor',
        },
    ]
}

const statistics = {
    id: '40',
    label: 'menu.statistics',
    path: '/main/statistics',
    icon: 'el-icon-data-line',
    children: [
        {
            id: '40-1',
            label: 'menu.statistics_base',
            path: '/main/statistics/base',
        },
        {
            id: '40-2',
            label: 'menu.statistics_workload',
            path: '/main/statistics/workload',
        },
        {
            id: '40-5',
            label: 'menu.statistics_screen',
            path: '/main/statistics/screen',
        },
        {
            id: '40-3',
            label: 'menu.statistics_quality',
            path: '/main/statistics/quality',
        },
        {
            id: '40-4',
            label: 'menu.statistics_bp',
            path: '/main/statistics/bp',
        },
    ]
}

const settings = {
    id: '50',
    label: 'menu.settings',
    path: '/main/settings',
    icon: 'el-icon-setting',
    children: [
        {
            id: '50-1',
            label: 'menu.settings_user',
            path: '/main/settings/user',
        },
    ]
}

const oplog = {
    id: '120',
    label: 'menu.oplog',
    path: '/main/oplog',
    icon: 'el-icon-notebook-1',
}

let data = {
    getMenuItems() {
        let menu = []
        menu.push(dastboard)
        menu.push(hdata)
        menu.push(dispatch)
        menu.push(statistics)
        menu.push(settings)
        if (localStorage.getItem('username') == 'admin') {
            menu.push(oplog)
        }
        return menu
    }
}


Mock.mock('/api/menu', () => {
    return {code: 200, data: data.getMenuItems()}
})