import Mock from 'mockjs'
import utils from '../utils'

let data = [
    // {id: 1, created:'2022-09-29 18:00:01', target:'用户', user:'user001', content: '用户从 222.104.1.130 登陆'},
    // {id: 2, created:'2022-09-29 18:10:21', target:'用户', user:'user001', content: '上传了100条用户记录'},
    // {id: 3, created:'2022-09-30 08:22:33', target:'机构', user:'大庆油田总医院', content: '接口数据同步完成'},
    // {id: 4, created:'2022-09-30 18:10:24', target:'机构', user:'大庆油田总医院', content: '修改了特殊项目控制'},
]

Mock.mock(/\/api\/oplog/, 'get', (req) => {
    let k = utils.getUrlQuery(req.url)
    let qs = data
    qs = qs.filter(e => !k.keywords || e.name.toLowerCase().includes(k.keywords.toLowerCase()))
    return {
        code: 200,
        data: {
            total: qs.length,
            records: utils.pagination(qs, k.page, k.pageSize)
        }
    }
})