<template>
    <el-select :value="value" @input="$emit('input',$event)" placeholder="请选择" :size="size" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"/>
    </el-select>
</template>
<script>
    export default {
        name: "selectDoctor",
        props: {
            value: {
                type: [String, Number,]
            },
            hospital_id: {
                required: true
            },
            department_id: {
                required: true
            },
            size: {
                default: 'small'
            }
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            hospital_id(newVal) {
                this.getData()
            },
            department_id() {
                this.getData()
            }
        },
        data() {
            return {
                options: []
            }
        },
        methods: {
            async getData() {
                if (this.hospital_id && this.department_id) {
                    const resp = await this.$http.get(`/mingde/doctor/?hospital_id=${this.hospital_id}&department_id=${this.department_id}`)
                    this.options = resp.data.data.data
                }
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>
<style lang="scss" scoped>
</style>
