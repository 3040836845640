<template>
    <el-dialog title="编辑" :visible="visible" width="40%" @close="close">
        <el-tabs v-model="activeName">
            <el-tab-pane label="员工信息" name="tab1">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="form.name" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="IP 地址" prop="ipaddr" :rules="{ required: true, message: 'IP地址不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="form.ipaddr"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model.trim="form.desc"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button @click="close">{{$t('form.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('form.ok')}}
                </el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            isNew: {
                default: false
            }
        },
        data() {
            return {
                loading: false,
                activeName: 'tab1',
            }
        },
        methods: {
            async formSubmit() {
                if (this.form.start && !this.form.end) {
                    this.$message.warning("结束时间必填")
                    return
                }
                if (!this.form.start && this.form.end) {
                    this.$message.warning("开始时间必填")
                    return
                }
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                const resp = await this.$http.post('/whiteList', this.form)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success('保存成功')
                    this.$emit('update')
                    this.$emit('close')
                }
            },
            close() {
                this.$emit("close")
            },
        },
        mounted() {

        }
    };
</script>

<style scoped>
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
